import React, { Component, createRef } from "react";
import * as R from "ramda";
import {
  Animate,
  CameraAndControls,
  Helpers,
  Lights,
  ModelLoader,
  Settings,
  Store,
  MouseEvents,
} from "./modules";
import { ThreeModuleProps } from "./ThreeInterface";
import { delay } from "../../utils";
import { Particles } from "./modules/Particles";
import { Line } from "./modules/Line";
import { VerticesLoader } from "./modules/VerticiesLoader";

export class VirtualRoom extends Component<ThreeModuleProps, any> {
  canvasRef = createRef<HTMLDivElement>();

  store: Store;

  settings: Settings;

  constructor(props: ThreeModuleProps) {
    super(props);
    this.store = new Store(props);
    this.settings = new Settings(this.store);
  }

  async componentDidMount() {
    if (this.canvasRef.current) {
      this.store.ref = this.canvasRef.current;
    }
    await this.init();
  }

  componentWillUnmount() {
    this.settings.cleanUp();
    this.store.cameraAndControls?.cleanUp();
    this.store.lights.cleanUp();
    this.store.animate.cleanUp();
  }

  async componentDidUpdate(prevProps: ThreeModuleProps) {
    this.store.updateProps(this.props);
    const { model, selectedInstance, selectedIndexes } = this.props;
    if (prevProps.model?.src !== model?.src) {
      await this.updateModels();
    }
    if (prevProps.selectedInstance !== selectedInstance) {
      await this.updateSelectedParticles();
    }
    if (!R.equals(prevProps.selectedIndexes, selectedIndexes)) {
      this.store.particles.updateParticles(prevProps.selectedIndexes);
      this.store.line.drawLine();
    }
  }

  async updateSelectedParticles() {
    await this.store.verticesLoader.loadVertices();
    this.store.particles.removeParticles();
    this.store.particles.createParticles();
    await delay(100);
    this.store.cameraAndControls.updateControlsTarget();
  }

  async updateModels() {
    if (!this.store || !this.store.modelLoader) {
      return;
    }
    await this.store.modelLoader.updateGltfModels();
    await this.store.verticesLoader.loadVertices();
    // await delay(1000);
    this.store.particles.removeParticles();
    this.store.particles.createParticles();
    this.store.cameraAndControls.updateControlsTarget();
  }

  async init() {
    await this.settings.init();

    this.store.modelLoader = new ModelLoader(this.store);

    await this.store.modelLoader.initializeScene();

    this.store.helpers = new Helpers(this.store);
    this.store.helpers.init();

    this.store.cameraAndControls = new CameraAndControls(this.store);
    this.store.cameraAndControls.setCamera();
    this.store.cameraAndControls.setControls();

    this.store.lights = new Lights(this.store);
    this.store.lights.setAmbientLight();
    this.store.lights.setDirectionalLights();

    this.settings.resize();

    this.store.animate = new Animate(this.store);
    this.store.animate.animate(0);

    await this.afterInit();
  }

  async afterInit() {
    this.store.verticesLoader = new VerticesLoader(this.store);
    await this.store.verticesLoader.loadVertices();
    this.store.particles = new Particles(this.store);
    this.store.particles.createParticles();

    this.store.line = new Line(this.store);

    this.store.mouseEvents = new MouseEvents(this.store);
  }

  render() {
    return <div ref={this.canvasRef} className="canvas-wrapper" />;
  }
}
