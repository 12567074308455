import * as R from "ramda";
import { createSelector } from "reselect";
import { ModelInstanceType, StoreType } from "Inrefaces";

export const getModels = (state: StoreType) => state.models;

export const getModelIsLoading = createSelector(
  getModels,
  R.prop("modelLoading")
);

export const getModelLoadedSource = createSelector(getModels, R.prop("model"));

export const getModelInstances = createSelector(
  getModels,
  R.prop("modelInstances")
);

export const getModelSelectedInstance = createSelector(
  getModels,
  R.prop("selectedModelInstance")
);

export const getModelSelectedAsset = createSelector(
  getModels,
  R.prop("selectedAsset")
);

export const getModelSelectedAssetIndexes = createSelector(
  getModelSelectedInstance,
  getModelSelectedAsset,
  getModelInstances,
  (selectedInstance, selectedAsset, instances) =>
    R.pipe<ModelInstanceType[], any, number[]>(
      R.find(R.propEq("name", selectedInstance)),
      R.ifElse(
        R.isNil,
        R.always([]),
        R.pipe(
          R.prop("measurements"),
          R.find(R.propEq("name", selectedAsset)),
          R.propOr<number[]>([], "indexes")
        )
      )
    )(instances)
);
