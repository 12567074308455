import * as THREE from "three";
import * as R from "ramda";
import { Store } from "./Store";

export type ParticleSphereMesh = THREE.Mesh<
  THREE.SphereBufferGeometry,
  THREE.MeshBasicMaterial
>;

export const SELECTED_INDEX_COLOR = new THREE.Color("green");
export const NOT_SELECTED_INDEX_COLOR = new THREE.Color("red");

export class Particles {
  store: Store;

  particles: THREE.Group;

  sphereGeo = new THREE.SphereBufferGeometry(0.002, 8, 8);

  sphereBasicMat = new THREE.MeshBasicMaterial({
    color: NOT_SELECTED_INDEX_COLOR,
  });

  constructor(store: Store) {
    this.store = store;
  }

  get newSphere(): ParticleSphereMesh {
    const sphere = new THREE.Mesh(this.sphereGeo, this.sphereBasicMat.clone());
    sphere.scale.divide(this.store.wrapper.scale);
    return sphere;
  }

  createParticles() {
    if (!this.store.verticesLoader.vertices.length) return;

    this.particles = new THREE.Group();
    this.particles.name = "particles_cloud";

    for (let i = 0; i < this.store.verticesLoader.vertices.length; i += 1) {
      const sphere = this.newSphere;
      sphere.position.copy(this.store.verticesLoader.vertices[i]);
      sphere.userData = {
        positionIndex: i,
        isParticle: true,
      };
      this.particles.add(sphere);
    }

    this.store.wrapper.add(this.particles);
  }

  removeParticles() {
    if (!this.particles) return;

    this.store.modelLoader.disposeModels(this.particles);
    this.particles = null;
  }

  selectParticles(indexes: number[]) {
    if (!indexes.length) return;
    indexes.forEach((index) => {
      const mesh = this.particles.children[index] as ParticleSphereMesh;
      if (!mesh) return;
      mesh.scale.multiplyScalar(2);
      mesh.material.color = SELECTED_INDEX_COLOR;
      mesh.material.userData.baseColor = SELECTED_INDEX_COLOR;
      mesh.material.needsUpdate = true;
    });
  }

  deselectParticles(indexes: number[]) {
    if (!indexes.length) return;
    indexes.forEach((index) => {
      const mesh = this.particles.children[index] as ParticleSphereMesh;
      if (!mesh) return;
      mesh.scale.divideScalar(2);
      mesh.material.color = NOT_SELECTED_INDEX_COLOR;
      mesh.material.userData.baseColor = NOT_SELECTED_INDEX_COLOR;
      mesh.material.needsUpdate = true;
    });
  }

  updateParticles(prevSelectedIndexes) {
    const { selectedIndexes } = this.store.props;
    const indexesToRemove = R.difference(prevSelectedIndexes, selectedIndexes);
    const indexesToAdd = R.difference(selectedIndexes, prevSelectedIndexes);

    this.deselectParticles(indexesToRemove);
    this.selectParticles(indexesToAdd);
  }
}
