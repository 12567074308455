import { all, select, put, takeEvery } from "redux-saga/effects";
import {
  addAssetIndex,
  addModelAsset,
  deleteModelAsset,
  // initializeModelInstances,
} from "./actions";
import {
  getModelSelectedAsset,
  getModelSelectedInstance,
  modelsAddAsset,
  modelsAddAssetIndex,
  modelsDeleteAsset,
  // modelsInitializeModelInstances,
  modelsSelectModelAsset,
} from "../store/models";
import { AssetType, } from "../Inrefaces";

// function* initializeModelInstancesSaga(
//   action: ReturnType<typeof initializeModelInstances>
// ) {
//   const { payload } = action;

//   const modelInstances: ModelInstanceType[] = payload.map((name) => ({
//     name,
//     measurements: [],
//   }));

//   yield put(modelsInitializeModelInstances(modelInstances));
// }

function* addModelAssetSaga(action: ReturnType<typeof addModelAsset>) {
  const {
    payload: { name, assetName },
  } = action;

  const asset: AssetType = {
    name: assetName,
    indexes: [],
  };
  yield put(modelsAddAsset({ name, asset }));
  yield put(modelsSelectModelAsset(assetName));
}

function* deleteAssetIndexSaga(action: ReturnType<typeof deleteModelAsset>) {
  const {
    payload: { assetName },
  } = action;

  // const asset: AssetType = {
  //   name: assetName,
  //   indexes: [],
  // };
  yield put(modelsDeleteAsset({ name: assetName }));
}

function* addAssetIndexSaga(action: ReturnType<typeof addAssetIndex>) {
  const { payload: index } = action;

  const instanceName = yield select(getModelSelectedInstance);
  const assetName = yield select(getModelSelectedAsset);

  if (!instanceName || !assetName) {
    alert("Select measurement");  // eslint-disable-line no-alert
    return;
  }

  yield put(modelsAddAssetIndex({ instanceName, assetName, index }));
}

export function* vroomSagas() {
  yield all([
    // yield takeEvery(
      // initializeModelInstances.getType(),
      // initializeModelInstancesSaga
    // ),
    yield takeEvery(addModelAsset.getType(), addModelAssetSaga),
    yield takeEvery(addAssetIndex.getType(), addAssetIndexSaga),
    yield takeEvery(deleteModelAsset.getType(), deleteAssetIndexSaga),
  ]);
}
