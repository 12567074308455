import TWEEN from "@tweenjs/tween.js";
import { Store } from "./Store";

export class Animate {
  store: Store;

  frameId: number = 0;

  constructor(store: Store) {
    this.store = store;
  }

  cancelAnimation() {
    cancelAnimationFrame(this.frameId);
  }

  draw(time: number) {
    if (this.store.renderer && this.store.cameraAndControls) {
      TWEEN.update(time);
      this.store.cameraAndControls.controls.update();
      this.store.renderer.render(
        this.store.scene,
        this.store.cameraAndControls.camera
      );
    }
  }

  animate(time: number) {
    this.frameId = requestAnimationFrame(this.animate.bind(this));
    this.draw(time);
  }

  cleanUp() {
    this.cancelAnimation();
  }
}
