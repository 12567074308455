import {
  ActionCreatorOrString,
  ActionCreatorOrString1,
  ActionCreatorOrString2,
  ActionCreatorOrString3,
  ActionCreatorOrString4,
  ActionCreatorOrString5,
  ActionCreatorOrString6,
  createReducer,
  Handlers,
  OnOff,
  Reducer,
} from "redux-act";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type CurriedHandler<S, P, M = {}> = (payload: P) => (state: S) => S;

declare global {
  interface CustomReducer<S> extends Reducer<S> {
    onc<Arg1, P, M = {}>(
      actionCreator: ActionCreatorOrString1<Arg1, P, M>,
      handler: CurriedHandler<S, P, M>
    ): CustomReducer<S>;
    onc<Arg1, Arg2, P, M = {}>(
      actionCreator: ActionCreatorOrString2<Arg1, Arg2, P, M>,
      handler: CurriedHandler<S, P, M>
    ): CustomReducer<S>;
    onc<Arg1, Arg2, Arg3, P, M = {}>(
      actionCreator: ActionCreatorOrString3<Arg1, Arg2, Arg3, P, M>,
      handler: CurriedHandler<S, P, M>
    ): CustomReducer<S>;
    onc<Arg1, Arg2, Arg3, Arg4, P, M = {}>(
      actionCreator: ActionCreatorOrString4<Arg1, Arg2, Arg3, Arg4, P, M>,
      handler: CurriedHandler<S, P, M>
    ): CustomReducer<S>;
    onc<Arg1, Arg2, Arg3, Arg4, Arg5, P, M = {}>(
      actionCreator: ActionCreatorOrString5<Arg1, Arg2, Arg3, Arg4, Arg5, P, M>,
      handler: CurriedHandler<S, P, M>
    ): CustomReducer<S>;
    onc<Arg1, Arg2, Arg3, Arg4, Arg5, Arg6, P, M = {}>(
      actionCreator: ActionCreatorOrString6<
        Arg1,
        Arg2,
        Arg3,
        Arg4,
        Arg5,
        Arg6,
        P,
        M
      >,
      handler: CurriedHandler<S, P, M>
    ): CustomReducer<S>;
    onc<P, M = {}>(
      actionCreator: ActionCreatorOrString<P, M>,
      handler: CurriedHandler<S, P, M>
    ): CustomReducer<S>;
  }
}

const mutate = <S>(customReducer: Reducer<S>): void => {
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign,no-return-assign
  customReducer.onc = (typeOrActionCreator, handler) => {
    const res = customReducer.on(typeOrActionCreator, (state, payload) =>
      handler(payload)(state)
    );
    mutate(res);
    return res;
  };
};

const customCreateReducer = <S>(
  handlers: Handlers<S> | OnOff<S>,
  defaultState?: S
): CustomReducer<S> => {
  const customReducer = createReducer<S>(handlers, defaultState);

  mutate<S>(customReducer);

  return customReducer as CustomReducer<S>;
};

export { customCreateReducer as createReducer };
