import React from "react";
import { Vroom } from "vroom";
import { ReduxProvider } from "providers";

function App() {
  return (
    <ReduxProvider>
      <Vroom />
    </ReduxProvider>
  );
}

export default App;
