import { createAction } from "redux-act";

export const initializeModelInstances = createAction<string[]>(
  "@SAGA/INITIALIZE_MODEL_INSTANCES"
);

export const addModelAsset = createAction<{
  name: string;
  assetName: string;
}>("@SAGA/ADD_MODEL_ASSET");

export const deleteModelAsset = createAction<{
  name: string;
  assetName: string;
}>("@SAGA/DELETE_MODEL_ASSET");

export const addAssetIndex = createAction<number>("@SAGA/ADD_ASSET_INDEX");
