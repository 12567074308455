import { createReducer } from "utils/customReduxAct";
import * as R from "ramda";
import { createAction } from "redux-act";
import { AssetType, ModelsLoadedSourceType, ModelsStoreType } from "Inrefaces";
import { lensIndexByName } from "./models.utils";

const defaultState = {
  modelLoading: false,
  model: null,
  modelInstances: [],
  selectedModelInstance: null,
  selectedAsset: null,
};

export const modelsSetModelLoading = createAction<boolean>(
  "MODELS_SET_MODEL_LOADING"
);

export const modelsUpdateLoadedSource = createAction<ModelsLoadedSourceType>(
  "MODELS_UPDATE_LOADED_SOURCE"
);

export const modelsInitializeModelInstances = createAction<
  ModelsStoreType["modelInstances"]
>("MODELS_INITIALIZE_MODEL_INSTANCES");

export const modelsSelectModelInstance = createAction<
  ModelsStoreType["selectedModelInstance"]
>("MODELS_SELECT_MODEL_INSTANCE");

export const modelsSelectModelAsset = createAction<
  ModelsStoreType["selectedAsset"]
>("MODELS_SELECT_MODEL_ASSET");

export const modelsAddAsset = createAction<{ name: string; asset: AssetType }>(
  "MODELS_ADD_ASSET"
);

export const modelsDeleteAsset = createAction<{ name: string; }>(
  "MODELS_DELETE_ASSET"
);

export const modelsAddAssetIndex = createAction<{
  instanceName: string;
  assetName: string;
  index: number;
}>("MODELS_ADD_ASSET_INDEX");

export const modelsReducer = createReducer<ModelsStoreType>({}, defaultState);

modelsReducer
  .onc(modelsAddAsset, ({ name, asset }) =>
    R.over(
      R.lensProp("modelInstances"),
      R.over(
        lensIndexByName(name),
        R.over(R.lensProp("measurements"), R.append(asset))
      )
    )
  )
  .onc(modelsDeleteAsset, ({ name }) =>
    R.over(
      R.lensProp("modelInstances"),
      R.over(
        lensIndexByName(name),        
        R.over(R.lensProp("measurements"), R.reject(o => (o as any).name === name))
      )
    )
  )
  .onc(modelsAddAssetIndex, ({ instanceName, assetName, index }) =>
    R.over(
      R.lensProp("modelInstances"),
      R.over(
        lensIndexByName(instanceName),
        R.over(
          R.lensProp("measurements"),
          R.over(
            lensIndexByName(assetName),
            R.over(R.lensProp("indexes"), R.append(index))
          )
        )
      )
    )
  )
  .onc(modelsSelectModelAsset, R.assoc("selectedAsset"))
  .onc(modelsSelectModelInstance, R.assoc("selectedModelInstance"))
  .onc(modelsInitializeModelInstances, R.assoc("modelInstances"))
  .onc(modelsSetModelLoading, R.assoc("modelLoading"))
  .onc(modelsUpdateLoadedSource, R.assoc("model"));
