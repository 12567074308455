import React, { useEffect } from "react";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  modelsInitializeModelInstances,
  modelsUpdateLoadedSource,
} from "store/models";
import { ModelInstanceType } from "Inrefaces";

export const LoadFileControl = () => {
  const [gender, setGender] = React.useState('male');
  const dispatch = useDispatch();

  const fetchMeasurements = () => {
    fetch(`https://tape-api.sizey.ai/measurements/202110`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
      .then(resp => resp.json())
      .then(r =>
        dispatch(modelsInitializeModelInstances([{ name: 'avatar', measurements: r.measurements || [] } as ModelInstanceType]))
      );
  };


  useEffect(() => {
    fetchMeasurements();
  });


  useEffect(() => {
    if (gender === 'male') {
      // eslint-disable-next-line no-console
      console.log("LOADING", `${window.location.origin}/male.obj`)
      dispatch(modelsUpdateLoadedSource({ src: `${window.location.origin}/male.obj` }));
    } else {
      // eslint-disable-next-line no-console
      console.log("LOADING", `${window.location.origin}/female.obj`)
      dispatch(modelsUpdateLoadedSource({ src: `${window.location.origin}/female.obj` }));
    }
  }, [gender]);

  return (
    <Grid container item>
      <FormControl component="fieldset">
        <Typography variant="h6">Gender</Typography>
        <RadioGroup aria-label="gender" name="gender1" value={gender} onChange={o => setGender(o.target.value)}>
          <FormControlLabel value="female" control={<Radio />} label="Female" />
          <FormControlLabel value="male" control={<Radio />} label="Male" />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};
