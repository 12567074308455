import * as THREE from "three";
import { Store } from "./Store";

export class Line {
  store: Store;

  line: THREE.Line;

  constructor(store: Store) {
    this.store = store;
  }

  removeLine() {
    if (!this.line) return;

    this.line.parent.remove(this.line);
    this.line = null;
  }

  drawLine() {
    const { selectedIndexes } = this.store.props;

    this.removeLine();

    if (!this.store.verticesLoader.vertices.length) return;

    const geometry = new THREE.BufferGeometry();
    const material = new THREE.LineBasicMaterial({
      color: "green",
      morphTargets: true,
      depthTest: true,
    });
    const positions = [];

    selectedIndexes.forEach((index) => {
      positions.push(...this.store.verticesLoader.vertices[index].toArray());
    });

    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(positions, 3)
    );

    this.line = new THREE.Line(geometry, material);
    this.store.wrapper.add(this.line);
  }
}
