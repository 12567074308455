import * as THREE from "three";
// import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { Store } from "./Store";
import { ThreeModuleProps } from "../ThreeInterface";

export class Settings {
  store: Store;

  envMap: THREE.Texture;

  constructor(store: Store) {
    this.store = store;
  }

  async init() {
    this.setScene();
    this.setRenderer();
    // await this.setEnvironment();
  }

  setScene() {
    this.store.scene = new THREE.Scene();
  }

  setRenderer() {
    const { width, height } = this.store;
    this.store.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    this.store.renderer.physicallyCorrectLights = true;
    this.store.renderer.outputEncoding = THREE.sRGBEncoding;
    this.store.renderer.setPixelRatio(window.devicePixelRatio);
    this.store.renderer.setSize(width, height);
    this.store.renderer.shadowMap.enabled = true;
    this.store.renderer.shadowMap.type = THREE.VSMShadowMap;
    this.store.ref.appendChild(this.store.renderer.domElement);
  }

  setEnvironment() {
    // return new Promise((done) => {
    //   const pmremGenerator = new THREE.PMREMGenerator(this.store.renderer);
    //   pmremGenerator.compileEquirectangularShader();

    //   // new RGBELoader()
    //   //   .setDataType(THREE.UnsignedByteType)
    //   //   .load(`${process.env.PUBLIC_URL}/studio_small.hdr`, (texture) => {
    //   //     this.envMap = pmremGenerator.fromEquirectangular(texture).texture;

    //   //     texture.dispose();
    //   //     pmremGenerator.dispose();
    //   //     done();
    //   //   });
    // });
  }

  setPureBackground() {
    this.store.scene.background = new THREE.Color("#ffffff");
    this.store.scene.fog = null;
  }

  resize() {
    const { width, height } = this.store.props;

    this.store.cameraAndControls.camera.aspect = width / height;
    this.store.cameraAndControls.camera.updateProjectionMatrix();
    this.store.renderer.setSize(width, height);
  }

  onResize(prevProps: ThreeModuleProps, props: ThreeModuleProps) {
    const { width, height } = props;
    if (prevProps.width !== width || prevProps.height !== height) {
      this.resize();
    }
  }

  cleanMaterial(material: THREE.Material) {
    material.dispose();

    Object.values(material).forEach((value) => {
      if (value instanceof THREE.Texture) {
        value.dispose();
      }
    });
  }

  disposeScene() {
    this.store.scene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose();

        if (child.material instanceof THREE.Material) {
          this.cleanMaterial(child.material);
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const material of child.material) this.cleanMaterial(material);
        }
      }
    });
  }

  cleanUp() {
    if (this.store.scene) {
      this.disposeScene();
      this.store.scene.children.length = 0;
    }
    if (this.store.renderer) {
      this.store.renderer.forceContextLoss();
      this.store.renderer.dispose();
    }
  }
}
