import React from "react";
import { Grid, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getModelInstances } from "store/models";

export const ExportControl = () => {
  const instances = useSelector(getModelInstances);

  const onSave = () => {
    const data = {
      measurements: instances[0].measurements
    };
    fetch('https://tape-api.sizey.ai/measurements/202110', { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data) }).then(o => o.json()).then(() => {
    });
  };

  return (
    <Grid container item>
      <Grid container direction="column">
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          Save
        </Button>

        <br />
      </Grid>
    </Grid>
  );
};
