import * as THREE from "three";
import { ThreeModuleProps } from "../ThreeInterface";
import { CameraAndControls } from "./CameraAndControls";
import { Lights } from "./Lights";
import { Animate } from "./Animate";
import { ModelLoader } from "./ModelLoader";
import { Helpers } from "./Helpers";
import { Particles } from "./Particles";
import { MouseEvents } from "./MouseEvents";
import { Line } from "./Line";
import { VerticesLoader } from "./VerticiesLoader";

export class Store {
  ref: HTMLDivElement;

  props: ThreeModuleProps;

  scene: THREE.Scene;

  renderer: THREE.WebGLRenderer;

  cameraAndControls: CameraAndControls;

  lights: Lights;

  animate: Animate;

  modelLoader: ModelLoader;

  helpers: Helpers;

  particles: Particles;

  mouseEvents: MouseEvents;

  line: Line;

  verticesLoader: VerticesLoader;

  constructor(props: ThreeModuleProps) {
    this.props = props;
  }

  get width() {
    return this.props.width;
  }

  get height() {
    return this.props.height;
  }

  get model(): THREE.Group {
    return this.modelLoader.model;
  }

  get wrapper(): THREE.Group {
    return this.modelLoader.wrapper;
  }

  get wrapperBox(): THREE.Box3 {
    return this.modelLoader.wrapperBox;
  }

  get wrapperBoxSize(): THREE.Vector3 {
    return this.wrapperBox.getSize(new THREE.Vector3());
  }

  get wrapperBoxCenter(): THREE.Vector3 {
    return this.wrapperBox.getCenter(new THREE.Vector3());
  }

  get selectedMesh(): THREE.Mesh<THREE.BufferGeometry> {
    const { selectedInstance } = this.props;
    if (!selectedInstance) return null;
    return this.model.getObjectByName(selectedInstance) as THREE.Mesh<
      THREE.BufferGeometry
    >;
  }

  get selectedMeshBox() {
    if (!this.selectedMesh) return null;
    return new THREE.Box3().setFromObject(this.selectedMesh);
  }

  get selectedMeshSize() {
    if (!this.selectedMesh) return null;
    return this.selectedMeshBox.getSize(new THREE.Vector3());
  }

  get selectedMeshCenter() {
    if (!this.selectedMesh) return null;
    return this.selectedMeshBox.getCenter(new THREE.Vector3());
  }

  get currentModelSize() {
    return this.selectedMeshSize || this.wrapperBoxSize;
  }

  get currentModelCenter() {
    return this.selectedMeshCenter || this.wrapperBoxCenter;
  }

  updateProps(props: ThreeModuleProps) {
    this.props = props;
  }
}
