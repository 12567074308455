import * as THREE from "three";
import { Store } from "./Store";

export class VerticesLoader {
  store: Store;

  fileLoader = new THREE.FileLoader();

  vertices: THREE.Vector3[] = [];

  constructor(store: Store) {
    this.store = store;
  }

  loadVertices() {
    const { model } = this.store.props;
    return new Promise((done) => {
      if (!model?.src) {
        done();
        return;
      }
      this.fileLoader.load(model.src, (text: string) => {
        if (text.indexOf("\r\n") !== -1) {
          text = text.replace(/\r\n/g, "\n");
        }
        if (text.indexOf("\\\n") !== -1) {
          text = text.replace(/\\\n/g, "");
        }
        const lines = text.split("\n");
        this.vertices = [];
        for (let i = 0, l = lines.length; i < l; i += 1) {
          const line = lines[i];
          line.trim();

          const lineFirstChar = line.charAt(0);

          if (lineFirstChar === "v") {
            const data = line.split(/\s+/);
            this.vertices.push(
              new THREE.Vector3(
                parseFloat(data[1]),
                parseFloat(data[2]),
                parseFloat(data[3])
              )
            );
          }
        }
        done();
      });
    });
  }
}
