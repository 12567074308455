import * as THREE from "three";
import { Store } from "./Store";

export class Lights {
  store: Store;

  ambientLight: THREE.AmbientLight;

  directionalLights: THREE.DirectionalLight[] = [];

  constructor(store: Store) {
    this.store = store;
  }

  setAmbientLight() {
    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
    this.store.scene.add(this.ambientLight);
  }

  setDirectionalLights() {
    this.setDirectionalLight(new THREE.Vector3(10, 30, 10), 0);
    this.setDirectionalLight(new THREE.Vector3(-10, 30, -10), 1);
    this.setDirectionalLight(new THREE.Vector3(10, 30, -10), 2);
    this.setDirectionalLight(new THREE.Vector3(-10, 30, 10), 3);
  }

  setDirectionalLight(position: THREE.Vector3, index) {
    this.directionalLights[index] = new THREE.DirectionalLight(0xffffff, 0.6);
    this.directionalLights[index].position.copy(position);
    this.directionalLights[index].castShadow = true;
    this.store.scene.add(this.directionalLights[index]);
    this.directionalLights[index].shadow.bias = -0.0005;
    this.directionalLights[index].shadow.radius = 4;
    this.directionalLights[index].shadow.mapSize = new THREE.Vector2(
      2048,
      2048
    );

    this.directionalLights[index].shadow.camera.left = -10;
    this.directionalLights[index].shadow.camera.right = 10;
    this.directionalLights[index].shadow.camera.bottom = -10;
    this.directionalLights[index].shadow.camera.top = 10;
  }

  cleanUp() {
    this.ambientLight = null;
    this.directionalLights = null;
  }
}
