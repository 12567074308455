import React, { useState } from "react";
import ReactResizeDetector from "react-resize-detector";
import { CircularProgress, Grid, withStyles } from "@material-ui/core";
import {
  getModelIsLoading,
  getModelLoadedSource,
  getModelSelectedAssetIndexes,
  getModelSelectedInstance,
  modelsSetModelLoading,
} from "store/models";
import { useDispatch, useSelector } from "react-redux";
import { VirtualRoom } from "./VirtualRoom";
import { addAssetIndex, initializeModelInstances } from "../../sagas/actions";
import { delay } from "../../utils";

const CircularProgressStyled = withStyles(() => ({
  root: {
    position: "absolute",
    left: "30px",
    top: "30px",
  },
}))(CircularProgress);

export const VirtualRoomWrapper = () => {
  const dispatch = useDispatch();
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const selectedInstance = useSelector(getModelSelectedInstance);
  const selectedIndexes = useSelector(getModelSelectedAssetIndexes);

  const model = useSelector(getModelLoadedSource);
  const isLoading = useSelector(getModelIsLoading);

  const setModelLoading = (loading: boolean) => {
    dispatch(modelsSetModelLoading(loading));
  };

  const setModelInstances = (value: string[]) => {
    dispatch(initializeModelInstances(value));
  };

  const selectModelIndex = async (index: number) => {
    await delay(1);
    dispatch(addAssetIndex(index));
  };

  const { width, height } = size;
  return (
    <Grid container xs={8}>
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={(w, h) => setSize({ width: w, height: h })}
      />
      {isLoading && <CircularProgressStyled />}
      {width && height ? (
        <VirtualRoom
          setModelLoading={setModelLoading}
          model={model}
          width={width}
          height={height}
          setModelInstances={setModelInstances}
          selectedInstance={selectedInstance}
          selectedIndexes={selectedIndexes}
          selectModelIndex={selectModelIndex}
        />
      ) : null}
    </Grid>
  );
};
