import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { Store } from "./Store";

const AXIS_SIZE = 5;

export class ModelLoader {
  store: Store;

  objLoader = new OBJLoader();

  model = new THREE.Group();

  wrapper = new THREE.Group();

  wrapperBox: THREE.Box3;

  constructor(store: Store) {
    this.store = store;
    this.addModel = this.addModel.bind(this);
  }

  addModel(): Promise<THREE.Group> {
    const { model } = this.store.props;
    return new Promise((done) => {
      if (!model?.src) {
        done();
        return;
      }

      // const ext = model.file.name.split(".").pop().toLowerCase();

      this.objLoader.load(
        model.src,
        (obj: THREE.Group) => {
          done(obj);
        },
        console.log // eslint-disable-line no-console
      );
    });
  }

  initializeInstances() {
    const { setModelInstances } = this.store.props;
    const instances = [];
    this.model.traverse((obj) => {
      if (obj instanceof THREE.Mesh) {
        if (!obj.name) {
          // const name = prompt(
          //   "Absent mesh name, mesh names are required for mapping, please add"
          // );
          obj.name = "avatar";
        }
        instances.push(obj.name);
      }
    });
    setModelInstances(instances);
  }

  async initializeScene() {
    this.wrapper = new THREE.Group();
    this.store.scene.add(this.wrapper);

    this.store.props.setModelLoading(true);

    this.model = await this.addModel();

    if (this.model) {
      this.wrapper.add(this.model);

      this.resizeModel();

      this.initializeInstances();

      this.updateMaterials();
    }

    this.wrapperBox = new THREE.Box3().setFromObject(this.wrapper);

    this.store.props.setModelLoading(false);
  }

  resizeModel() {
    // update scale to fixed size
    const modelSize = new THREE.Box3()
      .setFromObject(this.wrapper)
      .getSize(new THREE.Vector3());
    const multiplier = Math.min(
      ...new THREE.Vector3(AXIS_SIZE, AXIS_SIZE, AXIS_SIZE)
        .divide(modelSize)
        .toArray()
    );
    this.wrapper.scale.multiplyScalar(multiplier);

    // update position to ground
    const modelsBox = new THREE.Box3().setFromObject(this.wrapper);
    this.wrapper.translateY(-modelsBox.min.y);
  }

  updateMaterials() {
    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        if (child.material instanceof THREE.Material) {
          this.cleanMaterial(child.material);
        } else {
          for (const material of child.material) {
            this.cleanMaterial(material);
          }
        }
        child.material = new THREE.MeshStandardMaterial({
          color: "#ebccab",
        });
      }
    });
  }

  cleanMaterial(material: THREE.Material) {
    material.dispose();

    Object.values(material).forEach((value) => {
      if (value instanceof THREE.Texture) {
        value.dispose();
      }
    });
  }

  disposeModels(obj: THREE.Object3D) {
    obj.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child?.geometry?.dispose();

        if (child.material instanceof THREE.Material) {
          this.cleanMaterial(child.material);
        } else {
          for (const material of child.material) this.cleanMaterial(material);
        }
      }
    });
    obj.parent.remove(obj);
  }

  async updateGltfModels() {
    this.disposeModels(this.wrapper);
    await this.initializeScene();
  }
}
