import React from "react";
import { Grid } from "@material-ui/core";
import { VirtualRoomWrapper } from "./virtualRoom";
import { VroomControls } from "./controls";

export const Vroom = () => {
  return (
    <Grid container>
      <VirtualRoomWrapper />
      <VroomControls />
    </Grid>
  );
};
