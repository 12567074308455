import * as THREE from "three";
import { Store } from "./Store";
import { ParticleSphereMesh } from "./Particles";

export class MouseEvents {
  store: Store;

  raycaster = new THREE.Raycaster();

  mouse = new THREE.Vector2();

  intersected: ParticleSphereMesh;

  constructor(store: Store) {
    this.store = store;
    this.init();
  }

  init() {
    if (this.store.ref) {
      this.store.ref.addEventListener("mousemove", this.mousemove);
      this.store.ref.addEventListener("click", this.click);
    }
  }

  unmountListeners() {
    this.store.ref.removeEventListener("mousemove", this.mousemove);
    this.store.ref.removeEventListener("click", this.click);
  }

  click = () => {
    this.clickParticle();
  };

  mousemove = (event: MouseEvent) => {
    this.setMouseRaycastActions(event);
  };

  setMouse(event) {
    const { width, height } = this.store;
    const { left, top } = event.target.getBoundingClientRect();
    this.mouse.x = ((event.clientX - left) / width) * 2 - 1;
    this.mouse.y = -((event.clientY - top) / height) * 2 + 1;
  }

  clickParticle() {
    const { selectModelIndex } = this.store.props;
    if (!this.intersected) return;
    const { positionIndex } = this.intersected.userData;

    this.deselectParticle();
    selectModelIndex(positionIndex);
  }

  selectParticle() {
    if (!this.intersected) return;
    this.intersected.scale.multiplyScalar(1.5);

    this.intersected.userData.baseColor = this.intersected.material.color;

    this.intersected.material.color = new THREE.Color("blue");
    this.intersected.material.needsUpdate = true;
  }

  deselectParticle() {
    if (!this.intersected) return;
    this.intersected.scale.divideScalar(1.5);

    this.intersected.material.color = this.intersected.userData.baseColor;
    this.intersected.material.needsUpdate = true;

    this.intersected = null;
  }

  setMouseRaycastActions(event: MouseEvent) {
    event.preventDefault();
    this.setMouse(event);

    this.raycaster.setFromCamera(
      this.mouse,
      this.store.cameraAndControls.camera
    );

    if (!this.store.particles.particles) return;

    const intersects = this.raycaster.intersectObject(this.store.wrapper, true);

    if (intersects.length) {
      if (!intersects?.[0]?.object?.userData?.isParticle) {
        this.deselectParticle();
        return;
      }

      if (intersects[0].object.name !== this.intersected?.name) {
        this.deselectParticle();
      }
      if (intersects[0].object.name === this.intersected?.name) return;

      this.intersected = intersects[0].object as ParticleSphereMesh;
      this.selectParticle();
    } else if (this.intersected) {
      this.deselectParticle();
    }
  }
}
