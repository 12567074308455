import * as R from "ramda";
import { Lens } from "ramda";

export const lensIndexByName = (name: string): Lens =>
  R.lens(
    R.converge(R.nth, [R.findIndex(R.propEq("name", name)), R.identity]),
    R.uncurryN(2, (value) =>
      R.converge(R.update, [
        R.findIndex(R.propEq("name", name)),
        R.always(value),
        R.identity,
      ])
    )
  );
