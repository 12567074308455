import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Typography } from "@material-ui/core";
import {
  getModelInstances,
  getModelSelectedAsset,
  modelsSelectModelAsset,
  modelsSelectModelInstance,
} from "../../store/models";
import { AssetType, ModelInstanceType } from "../../Inrefaces";
import { addModelAsset, deleteModelAsset } from "../../sagas/actions";

type ModelAssetProps = {
  asset: AssetType;
  instanceName: string;
  onDelete: any;
};

const ModelAsset = ({ asset, instanceName, onDelete }: ModelAssetProps) => {
  const dispatch = useDispatch();
  const { name, indexes } = asset;
  const selectedAsset = useSelector(getModelSelectedAsset);

  const selectAsset = () => {
    dispatch(modelsSelectModelInstance(instanceName));
    dispatch(modelsSelectModelAsset(name));
  };

  const textColor = selectedAsset === name ? "primary" : "textSecondary";

  return (
    <Grid item container>
      <Grid container direction="column">
        <Grid container justify="space-between">
          <Typography variant="subtitle1" color={textColor} onClick={selectAsset}>
            {name}
          </Typography>
          <Button style={{ float: 'right' }} onClick={onDelete}>Delete</Button>
        </Grid>
        <Typography style={{ whiteSpace: "break-spaces" }} variant="body2">
          {indexes.join(" ")}
        </Typography>
      </Grid>
    </Grid>
  );
};

type ModelInstanceProps = {
  instance: ModelInstanceType;
};

const ModelInstance = ({ instance }: ModelInstanceProps) => {
  const dispatch = useDispatch();
  const { name, measurements } = instance;

  const addAsset = () => {
    const assetName = prompt("Measurement name");  // eslint-disable-line no-alert
    if (!assetName) {
      alert("You must give name");  // eslint-disable-line no-alert
      return;
    }
    if (measurements.find((v) => v.name === assetName)) {
      alert("Name not unique");  // eslint-disable-line no-alert
      return;
    }
    dispatch(addModelAsset({ assetName, name }));
    dispatch(modelsSelectModelInstance(name));
    dispatch(modelsSelectModelAsset(assetName));
    // dispatch(selectInstance());
  };

  const onDelete = (measurement) => {
    dispatch(deleteModelAsset({ assetName: measurement.name, name }));
  }

  return (
    <Grid item container>
      <Grid container direction="column">
        <Grid item container>
          <Grid container justify="flex-end">
            <Button onClick={addAsset}>Add measurement</Button>
          </Grid>
        </Grid>
        <Grid container style={{ paddingLeft: 15 }}>
          {measurements.map((measurement) => (
            <ModelAsset key={measurement.name} instanceName={name} onDelete={() => onDelete(measurement)} asset={measurement} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ModelInstancesControls = () => {
  const modelInstances = useSelector(getModelInstances);

  return (
    <Grid item container>
      <Grid container direction="column">
        <Typography variant="h6">Measurements</Typography>
        {modelInstances.map((instance) => (
          <ModelInstance key={instance.name} instance={instance} />
        ))}
      </Grid>
    </Grid>
  );
};
