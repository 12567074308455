import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { LoadFileControl } from "./LoadFile";
import { ModelInstancesControls } from "./ModelInstances";
import { ExportControl } from "./Export";

const ScrollableGrid = withStyles(() => ({
  root: {
    overflow: "scroll",
    height: "100%",
  },
}))(Grid);

export const VroomControls = () => {
  return (
    <ScrollableGrid container direction="column" alignItems="center" xs={4}>
      <Grid container direction="column" xs={12} spacing={4}>
        <LoadFileControl />
        <ModelInstancesControls />
        <ExportControl />
      </Grid>
    </ScrollableGrid>
  );
};
